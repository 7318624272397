import React from "react"
import { Link } from "gatsby"

const SocialBar_mobile = () => {
  let adres = ""
  if (typeof window !== "undefined") {
    adres = window.location.href
    // console.log(adres)
  }
  let SideMenu = () => {
    if (
      adres.indexOf("plakaty") > -1 ||
      adres.indexOf("mapy") > -1 ||
      adres.indexOf("mapa") > -1
    ) {
      return (
        <div className="sideMenu">
          <ul>
            <li>
              <Link to="/plakaty/lodzkie">ŁÓDZKIE</Link>
            </li>
            <li>
              <Link to="/plakaty/warszawskie">WARSZAWSKIE</Link>
            </li>
            <li>
              <Link to="/plakaty/archikony">ARCHIKONY</Link>
            </li>
            <li>
              <Link to="/mapy">MAPY</Link>
            </li>
            <li>
              <Link to="/plakaty/inne">INNE</Link>
            </li>
          </ul>
        </div>
      )
    } else return ""
  }

  return (
    <div>
      <div className="mobileOnly">
        <SideMenu />
      </div>
    </div>
  )
}
export default SocialBar_mobile
