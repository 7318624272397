import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const ImageMobile = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo_czyste.jpg" }) {
        childImageSharp {
          original {
            src
          }
        }
      }
    }
  `)

  return (
    <img
      alt="zuti grafika logo"
      src={data.placeholderImage.childImageSharp.original.src}
    />
  )
}

export default ImageMobile
