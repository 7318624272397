import React from "react"
import MessengerIcon from "../images/icons/messenger.svg"
import FacebookIcon from "../images/icons/facebook.svg"
import InstagramIcon from "../images/icons/instagram.svg"
import { Link } from "gatsby"

const Footer = () => (
  <footer>
    <div className="footerInnerWrapper">
      <div>
        <h1>Informacje</h1>
        <Link to="/regulamin">Regulamin</Link>
        <Link to="/print-info">Metoda wydruku i oprawa</Link>
        <Link to="/shippinfo">Realizacja zamówień</Link>
      </div>
      <div className="desktopOnly">
        <h1>Odbiór osobisty</h1>
        <p>ul. Gdańska 80, wejście A</p>
        <p>Łódź</p>
        <p>9.00-17.00</p>
      </div>
      <div>
        <h1>Kontakt</h1>
        <p>Gosia Zboina</p>
        <p>zutizuterful@gmail.com</p>
        <p>+48 603 884 830</p>
        {/* <div className="footerIcons">
          <a href="https://facebook.pl/zutigrafika">
            <FacebookIcon />
          </a>
          <a href="https://instagram.com/zuti_zuterful">
            <InstagramIcon />
          </a>
          <a href="https://m.me/zutigrafika">
            <MessengerIcon />
          </a>
        </div> */}
      </div>
    </div>
  </footer>
)

export default Footer
