import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import CartContext from "../context/cart-context"
import ShoppingCartIcon from "../images/icons/shopping-cart.svg"
import MobileMenu from "./menu/mobile"

const menuItems = [
  { name: "plakaty | posters", url: "/plakaty" },
  { name: "projekty | works", url: "/projekty" },
  { name: "o mnie | about me", url: "/about" },
  { name: "kontakt | contact", url: "/contact" },
]

const Header = ({ siteTitle }) => (
  <CartContext.Consumer>
    {cart => (
      <header className="header">
        <MobileMenu menuItems={menuItems} cart={cart} />
        <div className="menuContainer desktopOnly">
          {menuItems.map(item => {
            return (
              <Link to={item.url} key={item.name} className="menuLink">
                {item.name}
              </Link>
            )
          })}
          <Link className="menuCartLink desktopOnly" to="/cart">
            <ShoppingCartIcon className="cartIcon " /> {cart.cartItems.length}
          </Link>
        </div>
      </header>
    )}
  </CartContext.Consumer>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
