import React from "react"
import LogoMobile from "../logo-mobile"
import { Link } from "gatsby"
import ShoppingCartIcon from "../../images/icons/shopping-cart.svg"
import FacebookIcon from "../../images/icons/facebook.svg"
import InstagramIcon from "../../images/icons/instagram.svg"
import MenuIcon from "../../images/icons/menu.svg"

class MobileMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: false,
    }
    this.toggleMenu = this.toggleMenu.bind(this)
  }
  toggleMenu() {
    this.setState({
      status: true,
    })
  }
  render() {
    return (
      <>
        <Link to="/" className="mobileOnly logo">
          <LogoMobile />
        </Link>
        <div
          className={
            this.state.status
              ? "mobileMenuToggleVisibility menuIcons"
              : "mobileOnly menuIcons"
          }
        >
          <a href="https://facebook.pl/zutigrafika">
            <FacebookIcon />
          </a>
          <a href="https://instagram.com/zuti_zuterful">
            <InstagramIcon />
          </a>
          <Link to="/cart">
            <ShoppingCartIcon /> {this.props.cart.cartItems.length}
          </Link>
          <MenuIcon onClick={this.toggleMenu} />
        </div>
        <div
          className={
            this.state.status
              ? "mobileOnly menuContainer"
              : "mobileMenuToggleVisibility menuContainer"
          }
        >
          {this.props.menuItems.map(item => {
            return (
              <Link to={item.url} key={item.name} className="menuLink">
                {item.name}
              </Link>
            )
          })}
        </div>
      </>
    )
  }
}
export default MobileMenu
