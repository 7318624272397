import React from "react"
import { Link } from "gatsby"
import Logo from "../../components/logo"
import FacebookIcon from "../../images/icons/facebook.svg"
import InstaIcon from "../../images/icons/instagram.svg"
import MessengerIcon from "../../images/icons/messenger.svg"

const SocialBar = () => {
  let adres = ""
  if (typeof window !== "undefined") {
    adres = window.location.href
    // console.log(adres)
  }
  let SideMenu = () => {
    if (
      adres.indexOf("plakaty") > -1 ||
      adres.indexOf("mapy") > -1 ||
      adres.indexOf("mapa") > -1
    ) {
      return (
        <div className="sideMenu">
          <ul>
            <li>
              <Link to="/plakaty/lodzkie">ŁÓDZKIE</Link>
            </li>
            <li>
              <Link to="/plakaty/warszawskie">WARSZAWSKIE</Link>
            </li>
            <li>
              <Link to="/plakaty/archikony">ARCHIKONY</Link>
            </li>
            <li>
              <Link to="/mapy">MAPY</Link>
            </li>
            <li>
              <Link to="/plakaty/inne">INNE</Link>
            </li>
          </ul>
        </div>
      )
    } else return ""
  }

  return (
    <div className="desktopOnly">
      <Link
        to="/"
        style={{ display: "inline-block", verticalAlign: "text-top" }}
      >
        <div className="logo">
          <Logo />
        </div>
      </Link>
      <SideMenu />

      <div className="socialBar">
        <p>
          <a href="https://facebook.pl/zutigrafika">
            <FacebookIcon className="socialIcon" />
          </a>
          <a href="https://instagram.com/zuti_zuterful">
            <InstaIcon className="socialIcon" />
          </a>
          <a href="https://m.me/zutigrafika">
            <MessengerIcon className="socialIcon" />
          </a>
        </p>
      </div>
    </div>
  )
}
export default SocialBar
